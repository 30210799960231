import Vue from 'vue';
import App from './App';
import AppPartner from './AppPartner';

import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

// BootstrapVue add
import BootstrapVue from 'bootstrap-vue';
// Router & Store add
import { fnRouterBeforeEach } from './routers';
import { routerParams as routerParamsMerchant } from './routers/merchant';
import { routerParams as routerParamsPartner } from './routers/partner';

import store from './store';
import filters from './filters';
// Multi Language Add
import en from './locales/en.json';
import ru from './locales/ru.json';
import { ENV, ISDEV, ISFEATURE, MERCHANT_URL, DOMAIN, DOMAIN_PROD, getSPARole } from '@/api/index';
import VueI18n from 'vue-i18n';
import moment from 'moment';
import { defaultLocale, localeOptions } from './constants/config';
// Notification Component Add
import Notifications from './components/Common/Notification';
// Breadcrumb Component Add
import Breadcrumb from './components/Common/Breadcrumb';
// RefreshButton Component Add
import RefreshButton from './components/Common/RefreshButton';
// Colxx Component Add
import Colxx from './components/Common/Colxx';
// Perfect Scrollbar Add
import vuePerfectScrollbar from 'vue-perfect-scrollbar';
import VueCardFormat from 'vue-credit-card-validation';
import contentmenu from 'v-contextmenu';
import VueLineClamp from 'vue-line-clamp';
import VCalendar from 'v-calendar';
// import 'v-calendar/lib/v-calendar.min.css'
import VueScrollTo from 'vue-scrollto';
import VueClipboard from 'vue-clipboard2';
import Vuelidate from 'vuelidate';
import VueMeta from 'vue-meta';
import VueRouter from 'vue-router';
import axios from 'axios';
import VueYoutube from 'vue-youtube';
import VueCookies from 'vue-cookies';
import { utmList } from '@/models/utm';
//import * as Sentry from "@sentry/vue";
//import { BrowserTracing } from "@sentry/tracing";
import { USER_ROLES } from '@/models/user';
import { STORAGE, STORAGE_PARTNER_LINK_HASH } from './api/storage';
import { defaultColor } from '@/constants/config';
import { DEVICE } from '@/utils/device';
import VueMask from 'v-mask';
import 'vue-multiselect/dist/vue-multiselect.min.css';

/* https://ssense.github.io/vue-carousel/ */
import VueCarousel from 'vue-carousel';

/* https://github.com/apexcharts/vue-apexcharts */
import VueApexCharts from 'vue-apexcharts';

VueClipboard.config.autoSetContainer = true;

Vue.use(VueMask);
Vue.use(VueCookies);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(VueI18n);
Vue.use(VueClipboard);
Vue.use(VueMeta);
Vue.use(VueYoutube);
Vue.use(VueRouter);
Vue.use(VueCarousel);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

const messages = { en: en, ru: ru };
export const locale =
    STORAGE.getLang() && localeOptions.filter(x => x.id === STORAGE.getLang()).length > 0
        ? STORAGE.getLang()
        : defaultLocale;
STORAGE.setLang(locale);
const i18n = new VueI18n({
    locale: locale,
    fallbackLocale: 'ru',
    messages,
});

axios.interceptors.request.customLocale = locale;

moment.locale(locale);

Vue.use(Notifications);
Vue.component('piaf-breadcrumb', Breadcrumb);
Vue.component('b-refresh-button', RefreshButton);
Vue.component('b-colxx', Colxx);
Vue.component('vue-perfect-scrollbar', vuePerfectScrollbar);
Vue.use(VueCardFormat);
Vue.use(require('vue-shortkey'));
Vue.use(contentmenu);
Vue.use(VueLineClamp, {
    importCss: true,
});
Vue.use(VCalendar, {
    firstDayOfWeek: 2, // ...other defaults,
    formats: {
        title: 'MMM YY',
        weekdays: 'WW',
        navMonths: 'MMMM',
        input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
        dayPopover: 'L',
    },
    datePickerShowDayPopover: false,
    popoverExpanded: true,
    popoverDirection: 'bottom',
});
Vue.use(VueScrollTo);

Vue.mixin({
    data: () => ({
        isMobile: DEVICE.isMobile(),
        isLarge: DEVICE.isLarge(),
        isTablet: DEVICE.isTablet(),
        isWindows: DEVICE.isWindows(),
        discountNeuro: '-25%',
        discountTokensOne: '10%',
        discountTokensTwo: '25%',
        tokenCoins: [100, 250, 1000, 5000],
    }),
    computed: {
        ...mapGetters({
            me: 'auth/me',
        }),
        botLink() {
            return `https://t.me/${this.$t('contacts.manager_bot')}`;
        },
        botManagerName() {
            if (ISDEV) return 'PaywallDevManagerBot';
            if (ISFEATURE) return 'PaywallFeatureManagerBot';

            return `${this.$t('contacts.manager_bot')}`;
        },
        botPay() {
            return `https://t.me/${this.$t('contacts.pay_bot')}`;
        },
        botPayName() {
            return this.$t('contacts.pay_bot');
        },
        botSupport() {
            return this.isLocaleRu ? `https://t.me/${this.$t('contacts.support_tg_bot')}` : this.support;
        },
        botSupportName() {
            return this.isLocaleRu ? this.$t('contacts.support_tg_bot') : this.$t('contacts.support_tg');
        },
        botNews() {
            return `https://t.me/${this.$t('contacts.social_tg')}`;
        },
        botNeuroNews() {
            return `https://t.me/+7vLvcD-6gbIwYjNi`;
        },
        botLearn() {
            return `https://t.me/${this.$t('contacts.learn')}`;
        },
        support() {
            return `https://t.me/${this.$t('contacts.support_tg')}`;
        },
        supportBoth() {
            return `https://t.me/${this.$t('contacts.support_both_lang')}`;
        },
        loginUrl() {
            let params = _.map(this.$route.query, (q, i) => i + '=' + q).join('&');
            return `${MERCHANT_URL}/login${params ? '?' + params : ''}`;
        },
        isDarkColor() {
            return (
                (localStorage.getItem('themeColor') ? localStorage.getItem('themeColor') : defaultColor).indexOf(
                    'dark'
                ) > -1
            );
        },
        isLocaleRu() {
            return STORAGE.getLang() == 'ru';
        },
        islocaleRuSystemAndIp() {
            return defaultLocale == 'ru' || this.$store.getters['auth/geoIpRussia'];
        },
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    methods: {
        ...mapActions({
            pageView: 'auth/pageView',
            getGeoIpRussia: 'auth/geoIpRussia',
            setLang: 'setLang',
        }),
        onResize() {
            this.isMobile = DEVICE.isMobile();
        },
        async viewGeoIpRussia() {
            try {
                const ipRussia = await this.getGeoIpRussia();

                localStorage.removeItem('currentLanguage');

                if (defaultLocale == 'ru' || ipRussia) {
                    this.setLang('ru');
                    moment.locale('ru');
                } else {
                    this.setLang('en');
                    moment.locale('en');
                }
            } catch (e) {
                console.log('Error getGeoIpRussia', e);
            }
        },
        rememberPartnerHash(v) {
            if (v) this.$cookies.set(STORAGE_PARTNER_LINK_HASH, v, '7d', null, DOMAIN, null, 'Lax');
        },
        rememberUTM() {
            if (_.some(this.$route.query, (q, i) => i.match(/^utm/))) {
                _.each(utmList, i => this.$cookies.remove(i, null, window.location.hostname));
            }

            _.each(this.$route.query, (q, i) => {
                if (i.match(/^utm/)) {
                    let v = i;

                    if (i.match('_')) {
                        v =
                            i.split('_')[0] +
                            i.split('_')[1].charAt(0).toUpperCase() +
                            i.split('_')[1].slice(1).toLowerCase();
                    }

                    this.$cookies.set(v, q, '1M', null, window.location.hostname, null, 'Lax');
                }
            });

            if (window.document.referrer != null && window.document.referrer.length > 0)
                this.$cookies.set(
                    'referrer',
                    window.document.referrer,
                    '1M',
                    null,
                    window.location.hostname,
                    null,
                    'Lax'
                );
        },

        rememberPageView() {
            let params = {};

            _.each(this.$route.query, (q, i) => {
                if (i.match(/^utm/)) {
                    let v = i;

                    if (i.match('_')) {
                        v =
                            i.split('_')[0] +
                            i.split('_')[1].charAt(0).toUpperCase() +
                            i.split('_')[1].slice(1).toLowerCase();
                    }
                    params[v] = q;
                }
            });

            if (window.document.referrer != null && window.document.referrer.length > 0)
                params.httpReferrer = window.document.referrer;

            this.pageView(params).then(v => {
                this.$cookies.set('pageView', v.id, '1M', null, window.location.hostname, null, 'Lax');
            });
        },
    },
});

Vue.config.productionTip = false;

Object.keys(filters).forEach(v => Vue.filter(v, filters[v]));

function getVueParams() {
    let params = {};

    switch (getSPARole()) {
        case USER_ROLES.PARTNER: {
            params = {
                i18n,
                router: new VueRouter(routerParamsPartner),
                store,
                render: h => h(AppPartner),
            };

            break;
        }

        default:
            params = {
                i18n,
                router: new VueRouter(routerParamsMerchant),
                store,
                render: h => h(App),
            };
    }

    params.router.beforeEach(fnRouterBeforeEach);

    return params;
}

export const bus = new Vue(getVueParams()).$mount('#app');
