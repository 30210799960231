import axios from 'axios';
import { API, APIURL } from '../index';

export default _.defaults(
    {
        URL: APIURL + 'plans',
        plansPlain(data) {
            return axios.get(`${APIURL}plain_plans`, { params: data });
        },
        planChannels(data) {
            let id = data.id;
            delete data.id;

            return axios.post(`${this.URL}/${id}/channels`, data);
        },
        planGroups(data) {
            let id = data.id;
            delete data.id;

            return axios.post(`${this.URL}/${id}/groups`, data);
        },
        plansNeuro() {
            return axios.get(`${APIURL}neuro_plans`);
        },
    },
    API
);
