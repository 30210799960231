<template lang="pug">
    noscript
        img(v-if='id' height="1" width="1" style="display:none" :src='"https://www.facebook.com/tr?id=" + id + (eventName ? "&ev=" + eventName : "") + "&noscript=1"')
</template>
<script>
import { ISPROD } from '@/api/index';

export default {
    name: 'Facebook',
    props: {
        id: String,
        eventName: String,
        need: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({}),
    mounted() {},
    created() {
        if (ISPROD || this.need) {
            !(function (f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function () {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

            // eslint-disable-next-line no-undef
            fbq('init', this.id);

            // eslint-disable-next-line no-undef
            if (this.eventName) fbq('track', this.eventName);
        }
    },
};
</script>
