import api from '@/api/modules/users';
import apiAccounts from '@/api/modules/accounts';
import apiRequisites from '@/api/modules/requisites';
import { Card } from '@/models/card';
import { Payment } from '@/models/payment';
import { Withdraw } from '@/models/withdraw';
import { ModelExtend } from '@/models/model';
import { Account, Requisite } from '@/models/account';
import { Subscription } from '@/models/subscription';
import * as store from '../store';
import { saveAs } from 'file-saver';
import { Report } from '@/models/report';
import iconv from 'pika-iconv-lite';
import { DEVICE } from '@/utils/device';
import { User } from '../../models/user';

const state = _.defaults(
    {
        income: [],
        incomeTotal: null,
        isPendingIncome: null,
        isPendingWithdrawal: null,
        isPendingINN: null,
        pendingAccounts: false,

        withdrawals: [],
        withdrawalsTotal: null,
        withdrawSchedule: null,

        accounts: [],
        accountsActiveRemoved: [],
        activeCards: [],

        subscriptions: [],
        subscriptionsTotal: null,
        isPendingSubcriptions: null,

        audience: [],
        audienceUsers: [],
        audienceTotal: null,
        audienceStat: null,
        audienceDetails: null,
        isPendingAudience: null,

        wizardAddAccountOpened: false,
        wizardEditAccountOpened: false,
        wizardEditAccountStripeOpened: false,
        wizardCantEditAccountOpened: false,
        wizardCantRemoveAccountOpened: false,
        wizardAddPaymentSystemOpened: false,
        wizardAddUnitpayAccount: false,
        wizardRemoveRequisites: false,
    },
    store.state
);

const getters = _.defaults(
    {
        income: state => state.income,
        incomeTotal: state => state.incomeTotal,
        isPendingIncome: state => state.isPendingincome,
        isPendingWithdrawal: state => state.isPendingWithdrawal,
        isPendingINN: state => state.isPendingINN,
        pendingAccounts: state => state.pendingAccounts,

        withdrawals: state => state.withdrawals,
        withdrawalsTotal: state => state.withdrawalsTotal,
        withdrawSchedule: state => state.withdrawSchedule,

        accounts: state => state.accounts,
        accountsActiveRemoved: state => state.accountsActiveRemoved,
        activeCards: state => state.activeCards,
        activeCardsUniq: state => _.uniqBy(state.activeCards, i => i.pan),

        subscriptions: state => state.subscriptions,
        subscriptionsTotal: state => state.subscriptionsTotal,
        isPendingSubcriptions: state => state.isPendingSubcriptions,
        audience: state => state.audience,
        audienceUsers: state => state.audienceUsers,
        audienceTotal: state => state.audienceTotal,
        audienceStat: state => state.audienceStat,
        audienceDetails: state => state.audienceDetails,
        isPendingAudience: state => state.isPendingAudience,
        wizardAddAccountOpened: state => state.wizardAddAccountOpened,
        wizardAddUnitpayAccount: state => state.wizardAddUnitpayAccount,
        wizardEditAccountOpened: state => state.wizardEditAccountOpened,
        wizardEditAccountStripeOpened: state => state.wizardEditAccountStripeOpened,
        wizardCantEditAccountOpened: state => state.wizardCantEditAccountOpened,
        wizardCantRemoveAccountOpened: state => state.wizardCantRemoveAccountOpened,
        wizardAddPaymentSystemOpened: state => state.wizardAddPaymentSystemOpened,
        wizardRemoveRequisites: state => state.wizardRemoveRequisites,
    },
    store.getters
);

const actions = {
    async income({ state, commit }, data) {
        commit('isPendingIncome', true);

        try {
            let res = await api.income(data);
            commit('isPendingIncome', false);
            commit('setIncome', res.data['hydra:member']);
            commit('setIncomeTotal', res.data['hydra:totalItems']);
            commit(store.PENDING, false);

            return state.income;
        } catch (err) {
            commit('isPendingIncome', false);
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
        }
    },

    async incomeShort({ state, commit }, data) {
        commit('isPendingIncome', true);

        try {
            let res = await api.incomeShort(data);

            commit('isPendingIncome', false);
            commit('setIncome', res.data.items);
            commit('setIncomeTotal', res.data.totalItems);
            commit(store.PENDING, false);

            return state.income;
        } catch (err) {
            commit('isPendingIncome', false);
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
        }
    },

    async withdrawals({ state, commit }, data) {
        commit('isPendingWithdrawal', true);

        try {
            let res = await api.withdrawals(data);

            commit('isPendingWithdrawal', false);
            commit('setWithdrawals', res.data['hydra:member']);
            commit('setWithdrawalsTotal', res.data['hydra:totalItems']);
            commit(store.PENDING, false);

            return state.withdrawals;
        } catch (err) {
            commit('isPendingWithdrawal', false);
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
        }
    },

    async activeCards({ state, commit }, data = {}) {
        commit(store.START_REQUEST);
        data.status = 'ACTIVE';

        try {
            let res = await apiAccounts.cards(data);

            commit('setActiveCards', res.data['hydra:member']);
            commit(store.PENDING, false);

            return state.activeCards;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async partnerFinance({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiAccounts.partnerFinance(data);
            commit(store.PENDING, false);
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async accounts({ state, commit }, data) {
        commit(store.START_REQUEST);
        if (state.pendingAccounts) return true;

        commit('isPendingAccounts', true);

        try {
            let res = await apiAccounts.items(data);
            commit('setAccounts', res.data['hydra:member']);
            commit(store.PENDING, false);
            commit('isPendingAccounts', false);
            return state.accounts;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            commit('isPendingAccounts', false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async accountId({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiAccounts.id(data);
            commit(store.PENDING, false);

            return res.data != null ? new Account(res.data) : null;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async addAccount({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiAccounts.addAccounts(data);
            commit('addAccount', res.data);
            commit(store.PENDING, false);

            return _.first(state.accounts);
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async editAccount({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiAccounts.editAccounts(data);
            commit('editAccount', res.data);
            commit(store.PENDING, false);

            return _.first(state.accounts);
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async removeAccount({ state, commit }, data) {
        commit(store.START_REQUEST);
        let id = data.id;

        try {
            let res = await apiAccounts.remove(data);

            commit(store.PENDING, false);
            commit('removeAccount', { id: id });

            return true;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async checkUsdt({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiAccounts.checkUsdt(data);
            commit(store.PENDING, false);
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(err.response.data.error);
        }
    },

    async addRequisite({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiRequisites.add(data);
            commit('setRequisite', res.data);
            commit(store.PENDING, false);
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async editRequisite({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiRequisites.edit(data);
            commit('setRequisite', res.data);
            commit(store.PENDING, false);
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async removeShop({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiAccounts.removeShop(data);
            commit(store.PENDING, false);
            return true;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async addShop({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiAccounts.addShop(data);
            commit('setShop', res.data);
            commit(store.PENDING, false);
            return new ModelExtend(res.data);
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async editShop({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiAccounts.editShop(data);
            commit('setShop', res.data);
            commit(store.PENDING, false);
            return new ModelExtend(res.data);
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async accountRequisite({ state, commit }, id) {
        commit(store.START_REQUEST);

        try {
            let res = await apiRequisites.id(id);
            commit('setRequisite', res.data);
            commit(store.PENDING, false);
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async addWithdrawal({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiAccounts.addWithdrawals(data);

            commit('addWithdrawals', res.data);
            commit('addWithdrawalsTotal', 1);
            commit(store.PENDING, false);

            return _.first(state.withdrawals);
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async addCard({ state, commit }, data) {
        commit(store.START_REQUEST);
        let id = data.id;

        try {
            let res = await apiAccounts.addCard(data);
            commit('addCard', [data.pan, id]);
            commit(store.PENDING, false);
            commit('addCardActive', { pan: data.pan });
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return { error: store.parseError(err) };
        }
    },

    async addCardDev({ state, commit }, data) {
        commit(store.START_REQUEST);
        let id = data.id;

        try {
            let res = await apiAccounts.addCardDev(data);
            commit('addCard', [data.pan, id]);
            commit(store.PENDING, false);
            commit('addCardActive', { pan: data.pan });
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return { error: store.parseError(err) };
        }
    },

    async addCardExists({ state, commit }, data) {
        commit(store.START_REQUEST);
        let account = data.account;

        try {
            let res = await apiAccounts.addCardExists(data);

            commit('addCard', [res.data.pan, account]);
            commit(store.PENDING, false);

            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return { error: store.parseError(err) };
        }
    },

    async removeCard({ state, commit }, data) {
        commit(store.START_REQUEST);
        let id = data.id;

        try {
            let res = await apiAccounts.removeCard(data);

            commit(store.PENDING, false);

            if (data.is_possible) return res.data == 1;

            commit('removeCard', id);

            return true;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async removeCardFromAll({ state, commit }, data) {
        commit(store.START_REQUEST);
        let id = data.id;

        try {
            let res = await apiAccounts.removeCardFromAll(data);

            commit(store.PENDING, false);
            commit('removeCardFromAll', id);

            return true;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async unAttachCard({ state, commit }, data) {
        commit(store.START_REQUEST);
        let id = data.id;

        data.card = null;

        try {
            let res = await apiAccounts.edit(data);

            commit(store.PENDING, false);
            commit('removeCard', id);

            return true;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async subscriptions({ state, commit }, data) {
        commit(store.START_REQUEST);
        commit('setPendingSubscription', true);

        try {
            let res = await api.subscriptions(data);
            commit('setSubscriptions', res.data['hydra:member']);
            commit('setSubscriptionsTotal', res.data['hydra:totalItems']);
            commit('setPendingSubscription', false);
            commit(store.PENDING, false);
            return state.subscriptions;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async exportSubscriptions({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.exportSubscriptions(data);
            commit(store.PENDING, false);

            if (DEVICE.isWindows()) {
                saveAs(
                    new Blob([iconv.encode(res.data, 'win1251')], {
                        type: 'application/octet-stream;charset=windows-1251',
                    }),
                    'subscriptions.csv'
                );
                return true;
            }

            saveAs(new Blob([res.data], { type: 'application/octet-stream' }), 'subscriptions.csv');
            return true;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async audienceTable({ state, commit }, data) {
        commit(store.START_REQUEST);
        commit('setPendingAudience', true);

        try {
            let res = await api.audienceTable(data);
            commit('setAudience', res.data.table.items);
            commit('setAudienceUsers', res.data.users);
            commit('setAudienceTotal', res.data.table.totalItems);
            commit('setPendingAudience', false);
            commit(store.PENDING, false);
            return state.audience;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async audienceStat({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.audienceStat(data);
            commit('setAudienceStat', res.data[0]);
            commit(store.PENDING, false);
            return state.audienceStat;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async audienceDetails({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.audienceDetails(data);
            commit('setAudienceDetails', res.data);
            commit(store.PENDING, false);
            return state.audienceDetails;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async exportWithdrawals({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiAccounts.exportWithdrawals(data);
            commit(store.PENDING, false);
            saveAs(
                new Blob([res.data], { type: res.headers['content-type'] }),
                decodeURIComponent(res.headers['content-disposition'].split("filename*=utf-8''")[1])
            );

            return true;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async reports({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiAccounts.reports(data);
            return _.map(res.data['hydra:member'], m => new Report(m));
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async reportsDownload({ state, commit }, data) {
        commit(store.START_REQUEST);
        let name = data.fileName;
        delete data.fileName;

        try {
            let res = await apiAccounts.downloadReports(data);
            commit(store.PENDING, false);
            saveAs(
                new Blob([res.data], { type: res.headers['content-type'] }),
                name ? name : decodeURIComponent(res.headers['content-disposition'].split("filename*=utf-8''")[1])
            );

            return true;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async findCompany({ state, commit }, data) {
        commit(store.START_REQUEST);
        commit('setPendingINN', true);

        try {
            let res = await apiAccounts.findCompany(data);
            commit(store.PENDING, false);
            commit('setPendingINN', false);
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            commit('setPendingINN', false);
            return Promise.reject(store.parseError(err));
        }
    },

    async findBank({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiAccounts.findBank(data);
            commit(store.PENDING, false);

            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            return Promise.reject(store.parseError(err));
        }
    },

    async findAddress({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiAccounts.findAddress(data);
            commit(store.PENDING, false);

            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            return Promise.reject(store.parseError(err));
        }
    },

    async rates({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await apiAccounts.rates(data);
            commit(store.PENDING, false);

            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            return Promise.reject(store.parseError(err));
        }
    },

    async paymentsCSV({ commit, getters }, data) {
        commit(store.START_REQUEST, true);

        try {
            let res = await apiAccounts.paymentsCSV(data);
            commit(store.PENDING, false);
            saveAs(new Blob([res.data], { type: 'application/octet-stream' }), 'payments.csv');
            return true;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            return Promise.reject(store.parseError(err));
        }
    },
    async withdrawalsCSV({ commit, getters }, data) {
        commit(store.START_REQUEST, true);

        try {
            let res = await apiAccounts.withdrawalsCSV(data);
            commit(store.PENDING, false);
            saveAs(new Blob([res.data], { type: 'application/octet-stream' }), 'withdrawals.csv');
            return true;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            return Promise.reject(store.parseError(err));
        }
    },
};

const mutations = _.defaults(
    {
        setPendingINN(state, value) {
            state.isPendingINN = value;
        },
        setIncome(state, items) {
            state.income = _.map(items, i => new Payment(i));
        },
        setIncomeTotal(state, t) {
            state.incomeTotal = t;
        },

        setWithdrawals(state, items) {
            state.withdrawals = _.map(items, i => new Withdraw(i));
        },

        setWithdrawalsTotal(state, t) {
            state.withdrawalsTotal = t;
        },

        addWithdrawals(state, item) {
            state.withdrawals.unshift(new Withdraw(item));
        },

        addWithdrawalsTotal(state, t) {
            state.withdrawalsTotal += t;
        },

        setAccounts(state, items) {
            state.accounts = _.map(items, i => (i instanceof Account ? i : new Account(i)));
        },

        setAccountsActiveRemoved(state, items) {
            state.accountsActiveRemoved = _.map(
                _.filter(items, a => {
                    if (!a.isRemoved()) return a;
                    if (a.total?.amount != 0) return a;
                }),
                i => (i instanceof Account ? i : new Account(i))
            );
        },

        addAccount(state, item) {
            state.accounts.unshift(item instanceof Account ? item : new Account(item));
        },

        editAccount(state, item) {
            state.accounts = _.map(state.accounts, p =>
                p.id == item.id ? (item instanceof Account ? item : new Account(item)) : p
            );
        },

        setRequisite(state, item) {
            let index = _.findIndex(state.accounts, b => b.id == item.account);

            if (index == -1) return;

            state.accounts[index].requisite = item instanceof Requisite ? item : new Requisite(item);
        },

        setShop(state, item) {
            let index = _.findIndex(state.accounts, b => b.id == item.account);

            if (index == -1) return;

            state.accounts[index].shop = item instanceof ModelExtend ? item : new ModelExtend(item);
        },

        removeAccount(state, item) {
            let index = _.findIndex(state.accounts, i => i.id == item.id);

            if (index == -1 || state.accounts.length <= index) return;

            state.accounts.splice(index, 1);
        },

        addCard(state, p) {
            let bai = _.findIndex(state.accounts, b => b.id == p[1]);

            if (bai == -1) return;

            state.accounts[bai].addCard({ pan: p[0] });
        },

        removeCard(state, id) {
            let bai = _.findIndex(state.accounts, b => b.id == id);

            if (bai == -1) return;

            state.accounts[bai].removeCard();
        },

        removeCardFromAll(state, id) {
            _.each(state.accounts, a => {
                if (a.card && a.card.id == id) a.removeCard();
            });

            let bai = _.findIndex(state.activeCards, b => b.id == id);

            if (bai == -1) return;

            state.activeCards.splice(bai, 1);
        },

        setActiveCards(state, items) {
            state.activeCards = _.map(items, i => (i instanceof Card ? i : new Card(i)));
        },

        addCardActive(state, c) {
            state.activeCards.push(new Card(c));
        },

        setSubscriptions(state, items) {
            state.subscriptions = _.map(items, i => new Subscription(i));
        },

        setWithdrawSchedule(state, t) {
            state.withdrawSchedule = t;
        },

        setSubscriptionsTotal(state, t) {
            state.subscriptionsTotal = t;
        },

        setAudience(state, items) {
            state.audience = _.map(items, i =>
                i.subscription != null ? new Subscription(i.subscription) : new Subscription(i)
            );
        },

        setAudienceUsers(state, items) {
            state.audienceUsers = _.map(items, i => (i instanceof User ? i : new User(i)));
        },

        setAudienceTotal(state, t) {
            state.audienceTotal = t;
        },

        setAudienceStat(state, t) {
            state.audienceStat = t;
        },

        setAudienceDetails(state, t) {
            state.audienceDetails = t;
        },

        setPendingAudience(state, v) {
            state.isPendingAudience = v;
        },

        setPendingSubscription(state, v) {
            state.isPendingSubcriptions = v;
        },

        setPlansToSubscriptions(state, items) {
            _.each(state.subscriptions, s => {
                s.plan = _.find(items, i => i.id == s.plan.id);
            });
        },

        setWizardAddUnitpayAccount(state, v) {
            state.wizardAddUnitpayAccount = v;
        },

        setWizardAddAccountOpened(state, v) {
            state.wizardAddAccountOpened = v;
        },

        setWizardEditAccountOpened(state, v) {
            state.wizardEditAccountOpened = v;
        },

        setWizardEditAccountStripeOpened(state, v) {
            state.wizardEditAccountStripeOpened = v;
        },

        setWizardCantEditAccountOpened(state, v) {
            state.wizardCantEditAccountOpened = v;
        },
        setWizardCantRemoveAccountOpened(state, v) {
            state.wizardCantRemoveAccountOpened = v;
        },

        setWizardAddPaymentSystemOpened(state, v) {
            state.wizardAddPaymentSystemOpened = v;
        },

        setWizardRemoveRequisites(state, v) {
            state.wizardRemoveRequisites = v;
        },

        setAccountFinance(state, v) {
            _.each(state.accounts, a => {
                if (a.id == v.id) a.setFinance(v);
            });
        },

        setPaymentsParams(state, params) {
            _.each(state.income, p => {
                _.each(params, (param, ind) => {
                    switch (ind) {
                        case 'merchants': {
                            let value = _.find(param, pp => pp['@id'] == p.user);
                            if (value && value.id) p.user = value;
                            break;
                        }
                        case 'subscriptions': {
                            let value = _.find(param, pp => pp['@id'] == p.subscription);
                            if (value && value.id) p.subscription = value;
                            break;
                        }
                        case 'projects': {
                            let value = _.find(param, pp => pp['@id'] == p.project);
                            if (value && value.id) p.project = value;
                            break;
                        }
                        case 'plans': {
                            let value = _.find(
                                param,
                                pp => pp['@id'] == p.plan || pp['@id'] == p.plan.id || pp.id == p.plan.id
                            );
                            if (value && value.id) p.plan = value;
                            break;
                        }
                        case 'accounts': {
                            let value = _.find(param, pp => pp['@id'] == p.account);
                            if (value && value.id) p.account = value;
                            break;
                        }
                    }
                });
            });
        },
        isPendingAccounts(state, v) {
            state.pendingAccounts = v;
        },
        isPendingIncome(state, v) {
            state.pendingIncome = v;
        },
        isPendingWithdrawal(state, v) {
            state.pendingWithdraisPendingWithdrawal = v;
        },
    },
    store.mutations
);

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
