import _ from 'lodash';
import Telegram from '@/assets/img/socials/tg.svg';
import Vkontakte from '@/assets/img/socials/vk.svg';
import VkVideo from '@/assets/img/socials/vk_video.svg';
import Youtube from '@/assets/img/socials/youtube.svg';
import Dzen from '@/assets/img/socials/dzen.svg';
import Vcru from '@/assets/img/socials/vc.png';
import Facebook from '@/assets/img/socials/fb.svg';
import Instagram from '@/assets/img/socials/insta.svg';

export const defaultMenuType = 'menu-sub-hidden'; //'menu-default' // 'menu-hidden' 'menu-sub-hidden', 'menu-hidden';
export const defaultStartPath = '/app/dashboards/default';
export const searchPath = '/app/pages/miscellaneous/search';
export const buyUrl = 'https://1.envato.market/nEyZa';
import moment from 'moment-timezone';

import { STORAGE } from '@/api/storage';
import { APIURL } from '@/api/index';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 991;

const countriesByLocaleRu = ['ru', 'uk', 'be', 'az', 'ka', 'hy', 'kk', 'uz', 'ky', 'tg'];
export var registeredAt = '2020-01-01';

export let defaultLocale = 'en';

_.each(countriesByLocaleRu, l => {
    if (window.navigator.language.includes(l)) {
        defaultLocale = 'ru';
        return false;
    }
});

export const defaultDirection = 'ltr';
export const localeOptions = [
    { id: 'ru', name: 'RU', direction: 'ltr' },
    { id: 'en', name: 'EN', direction: 'ltr' },
];

export const currency = 'RUB';
export const apiUrl = '//api.coloredstrategies.com';
//export const isDemo = window.location.hostname.includes('paywall.vm');
export const isDemo = false;
export const themeRadiusStorageKey = 'flat';
export const defaultColor = 'light.figma';
export let color = localStorage.getItem('themeColor') ? localStorage.getItem('themeColor') : defaultColor;
export const colors = [];

export const foundationYear = 2020;
export const tablePerPage = 25;
export const tablePageSizes = [25, 50, 100];
export const tablePageSizesExtended = [
    { id: 25, title: '25' },
    { id: 50, title: '50' },
    { id: 100, title: '100' },
    { id: 200, title: '200' },
    { id: 500, title: '500' },
    { id: 1000, title: '1000' },
    { id: 2000, title: '2000' },
    { id: 5000, title: '5000' },
];

export const navList = [
    { hash: 'whatisit', title: 'Что это' },
    { hash: 'forwhom', title: 'Для кого' },
    { hash: 'howitworks', title: 'Как работает' },
    // {hash: 'examples', title: 'Примеры'},
    { hash: 'howmuchcanearn', title: 'Сколько можно заработать' },
    { hash: 'faq', title: 'FAQ', isLink: true },
    { hash: 'subscriber', title: 'Для подписчиков', isLink: true },
    { hash: 'blog', title: 'Блог', isLink: true, href: '//paywall.pw/blog' },
];
export const navListClubhouse = [
    { hash: 'howitworks', title: 'Как работает' },
    { hash: 'profit', title: 'Преимущества' },
    { hash: 'examples', title: 'Примеры' },
    { hash: 'whatisitpaywall', title: 'Что такое Paywall' },
    { hash: 'faq', title: 'FAQ', isLink: true },
    { hash: 'blog', title: 'Блог', isLink: true, href: '//paywall.pw/blog' },
];

export const localePicker = [
    {
        id: 'en',
        direction: 'ltr',
        format: 'dd mmm yy',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        monthNamesFull: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        firstDay: 1,
    },
    {
        id: 'ru',
        direction: 'ltr',
        format: 'dd mmm yy',
        separator: ' - ',
        applyLabel: 'Выбрать',
        cancelLabel: 'Отменить',
        weekLabel: 'Н',
        customRangeLabel: 'Выбрать даты',
        daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthNames: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
        monthNamesFull: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
        ],
        firstDay: 1,
    },
];

export let localePickerRanges = [
    {
        Yesterday: [moment().subtract(1, 'days').toDate(), moment.utc().subtract(1, 'days').toDate(), 2],
        Today: [moment.utc().toDate(), moment.utc().toDate(), 1],
        Week: [moment().utc().subtract(6, 'days').toDate(), moment.utc().toDate(), 1],
        'Last month': [moment.utc().subtract(31, 'days').toDate(), moment.utc().toDate(), 3],
        'This month': [moment.utc().startOf('month').toDate(), moment.utc().toDate(), 3],
        Quater: [moment.utc().subtract(2, 'month').startOf('month').toDate(), moment.utc().toDate(), 3],
        'This year': [moment.utc().startOf('year').toDate(), moment.utc().toDate(), 5],
        'All time': [moment(registeredAt).utc().toDate(), moment.utc().toDate(), 5],
    },
    {
        Вчера: [moment().subtract(1, 'days').toDate(), moment.utc().subtract(1, 'days').toDate(), 2],
        Сегодня: [moment.utc().toDate(), moment.utc().toDate(), 1],
        Неделя: [moment().utc().subtract(6, 'days').toDate(), moment.utc().toDate(), 1],
        'Текущий месяц': [moment.utc().startOf('month').toDate(), moment().toDate(), 3],
        Месяц: [moment.utc().subtract(31, 'days').toDate(), moment.utc().toDate(), 3],
        Квартал: [moment.utc().subtract(2, 'month').startOf('month').toDate(), moment.utc().toDate(), 3],
        'Текущий год': [moment.utc().startOf('year').toDate(), moment.utc().toDate(), 5],
        'Все время': [moment(registeredAt).utc().toDate(), moment.utc().toDate(), 5],
    },
];

export const dateList = function () {
    return [
        {
            id: 'hourly',
            title: this.$t('date.yesterday'),
            startDate: moment().subtract(1, 'days').format('YYYY-MM-DDT00:00:00'),
            endDate: moment().subtract(1, 'days').format('YYYY-MM-DDT23:59:59'),
        },
        {
            id: 'hourly',
            title: this.$t('date.today'),
            startDate: moment().format('YYYY-MM-DDT00:00:00'),
            endDate: moment().format('YYYY-MM-DDT23:59:59'),
        },
        {
            id: 'daily',
            title: this.$t('date.week'),
            startDate: moment().subtract(6, 'days').format('YYYY-MM-DDT00:00:00'),
            endDate: moment().format('YYYY-MM-DDT23:59:59'),
        },
        {
            id: 'daily',
            title: this.$t('date.month'),
            startDate: moment().subtract(31, 'days').format('YYYY-MM-DDT00:00:00'),
            endDate: moment().format('YYYY-MM-DDT23:59:59'),
        },
        {
            id: 'monthly',
            title: this.$t('date.quater'),
            startDate: moment().subtract(2, 'month').startOf('month').format('YYYY-MM-DDT00:00:00'),
            endDate: moment().format('YYYY-MM-DDT23:59:59'),
        },
        {
            id: 'monthly',
            title: this.$t('date.year'),
            startDate: moment().subtract(1, 'year').startOf('month').format('YYYY-MM-DDT00:00:00'),
            endDate: moment().format('YYYY-MM-DDT23:59:59'),
        },
        {
            id: 'monthly',
            title: this.$t('date.all'),
            startDate: moment(registeredAt).format('YYYY-MM-DDT00:00:00'),
            endDate: moment().format('YYYY-MM-DDT23:59:59'),
        },
    ];
};

export const setRegisterdAt = function (v) {
    registeredAt = v.split('T')[0];
};

export const socials = function () {
    return [
        { name: 'Telegram', href: this.botNews, src: Telegram },
        { name: 'Vkontakte', href: this.$t('contacts.social_vk'), src: Vkontakte },
        { name: 'VKVideo', href: this.$t('contacts.social_vk_video'), src: VkVideo },
        { name: 'Dzen', href: this.$t('contacts.social_dzen'), src: Dzen },
        { name: 'vc.ru', href: this.$t('contacts.social_vc'), src: Vcru },
        { name: 'Instagram', href: this.$t('contacts.social_inst'), src: Instagram },
        { name: 'Youtube', href: this.$t('contacts.social_youtube'), src: Youtube },
        { name: 'Facebook', href: this.$t('contacts.social_fb'), src: Facebook },
    ];
};

export const socialsRu = function () {
    return [
        { name: 'Telegram', href: this.botNews, src: Telegram },
        { name: 'Vkontakte', href: this.$t('contacts.social_vk'), src: Vkontakte },
        { name: 'VKVideo', href: this.$t('contacts.social_vk_video'), src: VkVideo },
        { name: 'Dzen', href: this.$t('contacts.social_dzen'), src: Dzen },
        { name: 'vc.ru', href: this.$t('contacts.social_vc'), src: Vcru },
    ];
};

export const socialsNeuro = function () {
    return [
        { name: 'Telegram', href: this.botNeuroNews, src: Telegram },
        { name: 'Vkontakte', href: this.$t('contacts.social_vk'), src: Vkontakte },
        { name: 'VKVideo', href: this.$t('contacts.social_vk_video'), src: VkVideo },
        { name: 'Dzen', href: this.$t('contacts.social_dzen'), src: Dzen },
        { name: 'vc.ru', href: this.$t('contacts.social_vc'), src: Vcru },
        { name: 'Instagram', href: this.$t('contacts.social_inst'), src: Instagram },
        { name: 'Youtube', href: this.$t('contacts.social_youtube'), src: Youtube },
        { name: 'Facebook', href: this.$t('contacts.social_fb'), src: Facebook },
    ];
};

export const socialsNeuroRu = function () {
    return [
        { name: 'Telegram', href: this.botNeuroNews, src: Telegram },
        { name: 'Vkontakte', href: this.$t('contacts.social_vk'), src: Vkontakte },
        { name: 'VKVideo', href: this.$t('contacts.social_vk_video'), src: VkVideo },
        { name: 'Dzen', href: this.$t('contacts.social_dzen'), src: Dzen },
        { name: 'vc.ru', href: this.$t('contacts.social_vc'), src: Vcru },
    ];
};

export const PickerPeriods = {
    HOURLY: 'hourly',
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly',
};

export const DROPZONE_OPTIONS = function () {
    return {
        headers: { Authorization: `Bearer ${STORAGE.getToken()}` },
        url: APIURL + 'images',
        timeout: 120000,
        paramName: 'image',
        parallelUploads: 1,
        maxNumberOfFiles: 1,
        maxFilesize: 5,
        filesizeBase: 1000,
        addRemoveLinks: true,
        autoProcessQueue: false,
        acceptedFiles: '.jpg, .jpeg, .png, .webp, .heic, .heif',
        dictDefaultMessage: this.removeBtnPressed ? dropzone_error.apply(this) : dropzone_empty,
        dictRemoveFile: '',
        dictCancelUpload: '',
        previewTemplate: `<img class="table-loader" src="/assets/img/current/loader.svg"/>`,
    };
};

const dropzone_error = function () {
    return `<img class="cover-icon" src="/assets/img/current/danger.svg"/>
<img class="cover-icon-hover" src="/assets/img/current/danger.svg"/>
<div class="b3 text-error">${this.$t('project.addCoverTitle')}</div>`;
};
let dropzone_empty = `<img class="cover-icon" src="/assets/img/current/cover${color.indexOf('dark') > -1 ? '-dark' : ''}.svg"/>
<img class="cover-icon-hover" src="/assets/img/current/cover-hover${color.indexOf('dark') > -1 ? '-dark' : ''}.svg"/>
<div class="b3 text-light-grey">16:9</div>`;
