import _ from 'lodash';
import { Model } from './model';
import { Project } from './project';
import { Plan } from './plan';
import { Merchant } from './merchant';
import { Money } from './money';
import { Comment } from './comment';
import { Account, PAY_SYSTEM } from './account';

export class Withdraw extends Model {
    constructor(data) {
        super(data);

        if (_.isNil(data)) {
            return;
        }

        _.assignIn(this, {
            price: new Money(data.price),
            method: data.method,
            purse: data.purse,
            type: data.type,
            status: data.status,
            unitpayId: data.unitpayId,
            fio: data.fio,
            organizationName: data.organizationName,
            paymentIds: data.paymentIds,
            from: data.from ? new Merchant(data.from) : null,
            to: data.to,
            statusO: _.find(STATUS, s => s.id == data.status),
            account: new Account(data.account),
            comment: data.comment,
            errorMessage: data.errorMessage,
        });
    }

    isWorld2ru() {
        return this.account.isStripe();
    }
    isUSDTWithdrawal() {
        return /^(T)[a-zA-Z0-9]{33}$/.test(this.purse);
    }

    isSuccess() {
        return this.status == STATUS[0].id;
    }
    isRequested() {
        return this.status == STATUS[1].id;
    }
    isChecked() {
        return this.status == STATUS[3].id;
    }
    isPaid() {
        return this.status == STATUS[4].id;
    }
    isRefund() {
        return this.status == STATUS[5].id;
    }
    isUsed() {
        return this.status == STATUS[6].id;
    }
    isError() {
        return this.status == STATUS[2].id;
    }
    isNotComplited() {
        return this.status == STATUS[7].id;
    }

    isTinkoff() {
        return this.type == 'TINKOFF';
    }
    hasPayments() {
        return this.paymentIds && _.isArray(this.paymentIds) && this.paymentIds.length > 0;
    }

    isOrganization() {
        return this.account.isOrganization();
    }
}

export const STATUS = [
    { id: 'SUCCESS', titleRU: 'Успешно', titleEN: 'SUCCESS' },
    { id: 'REQUESTED', titleRU: 'В процессе', titleEN: 'REQUESTED' },
    { id: 'ERROR', titleRU: 'Ошибка', titleEN: 'ERROR' },
    { id: 'CHECKED', titleRU: 'CHECKED', titleEN: 'CHECKED' },
    { id: 'PAID', titleRU: 'PAID', titleEN: 'PAID' },
    { id: 'REFUND', titleRU: 'Возврат', titleEN: 'REFUND' },
    { id: 'USED', titleRU: 'Успешно', titleEN: 'USED' },
    { id: 'NOT_COMPLETED', titleRU: 'NOT_COMPLETED', titleEN: 'NOT_COMPLETED' },
];
