<template lang="pug">
</template>

<script>
import { ISPROD, APIURL, ENV } from '@/api/index';
import axios from 'axios';
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
    name: 'YandexAnalitics',
    props: ['tid'],
    data: () => ({
        cid: null,
        isInit: false,
    }),
    created() {
        if (ISPROD) {
            (function (m, e, t, r, i, k, a) {
                m[i] =
                    m[i] ||
                    function () {
                        (m[i].a = m[i].a || []).push(arguments);
                    };
                m[i].l = 1 * new Date();
                (k = e.createElement(t)),
                    (a = e.getElementsByTagName(t)[0]),
                    (k.async = 1),
                    (k.src = r),
                    a.parentNode.insertBefore(k, a);
            })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

            // eslint-disable-next-line no-undef
            ym(this.tid, 'init', {
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
            });

            this.isInit = true;

            // eslint-disable-next-line no-undef
            ym(ENV.YM, 'getClientID', clientID => {
                this.cid = clientID;
                window.ymcid = this.cid;
            });
        }
    },
    methods: {
        ...mapActions({
            edit: 'auth/meEdit',
        }),
    },

    watch: {
        me(val) {
            if (val && val.id) {
                if (!val.ymIdPassedAt) {
                    if (this.isInit) {
                        // eslint-disable-next-line no-undef
                        ym(this.tid, 'setUserID', val.id);
                        this.edit({
                            id: this.me.id,
                            ymIdPassedAt: moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                        });
                    }
                }
            }
        },
    },
};
</script>
