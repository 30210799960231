import _ from 'lodash';
import { Model } from './model';
import { OnRecovery } from './onRecovery';
import { Recovered } from './recovered';
import { RecoveryEfficiency } from './recoveryEfficiency';
import { RecoveryEfficiencyByYears } from '@/models/recoveryEfficiencyByYears';
import { RecoveryEfficiencyComebacker } from '@/models/recoveryEfficiencyComebacker';

export class RecoveryAnalytics extends Model {
    constructor(data) {
        super(data);

        if (_.isNil(data)) {
            return;
        }

        let source = {
            onRecovery: new OnRecovery(data.onRecovery),
            recovered: new Recovered(data.recovered),
            recoveryEfficiency: data.recoveryEfficiency
                ? _.map(data.recoveryEfficiency, p => new RecoveryEfficiency(p))
                : data.recoveryEfficiency,
        };

        if (
            data.recoveryEfficiency[0] &&
            data.recoveryEfficiency[0].metrics !== undefined &&
            data.recoveryEfficiency[0].metrics !== undefined
        ) {
            source.recoveryEfficiency = data.recoveryEfficiency
                ? _.map(
                      data.recoveryEfficiency,
                      p => new RecoveryEfficiencyComebacker(p, data.recoveryEfficiency.indexOf(p))
                  )
                : data.recoveryEfficiency;
        }

        _.assignIn(this, source);
    }
}
