import VueNotify from './Notify.vue';

export default {
    install(Vue) {
        let Constr = Vue.extend(VueNotify);
        let Notify = new Constr();
        let vm = Notify.$mount();
        document.querySelector('body').appendChild(vm.$el);
        Vue.$notify = Vue.prototype.$notify = (type = 'success', message) => {
            Notify.show(type, message);
        };
    },
};
