import _ from 'lodash';
import { Model } from './model';
import { Plan } from './plan';
import { Consumer } from './consumer';

export class Comebacker extends Model {
    constructor(data) {
        super(data);

        if (_.isNil(data)) {
            return;
        }

        _.assignIn(this, {
            title: data.title,
            description: data.description,
            discount: data.discount,
            status: data.status,
            duration: data.duration,
            project: data.project,
            plans: data.plans ? _.map(data.plans, p => new Plan(p)) : data.plans,
            user: data.user != null ? new Consumer(data.user) : null,
            canBeDeleted: data.editable,
            countComebacks: data.countComebacks,
        });
    }

    getTimeFromMins() {
        let hours = Math.trunc(this.duration / 60 / 60);
        let minutes = Math.trunc(this.duration / 60) - hours * 60;
        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    }
}
