import api from '@/api/modules/bots';
import * as store from '../store';
import { _ } from 'core-js';

const state = _.defaults({}, store.state);

const getters = _.defaults({}, store.getters);

const actions = {
    async add({ commit, getters }, data) {
        commit(store.START_REQUEST, true);

        try {
            let res = await api.add(data);
            commit(store.PENDING, false);
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async edit({ commit, getters }, data) {
        commit(store.START_REQUEST, true);

        try {
            let res = await api.edit(data);
            commit(store.PENDING, false);
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async refresh({ commit, getters }, data) {
        commit(store.START_REQUEST, true);

        try {
            let res = await api.refresh(data);
            commit(store.PENDING, false);
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
};

const mutations = _.defaults({}, store.mutations);

export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations,
};
