import axios from 'axios';
import { API } from '../index';

export default _.defaults(
    {
        auth(data = {}) {
            return axios.post(`${this.URL}auth/login`, { ...data });
        },

        authSocial(data = {}) {
            return axios.post(`${this.URL}auth/social`, { ...data });
        },

        signup(data = {}) {
            return axios.post(`${this.URL}auth/register`, { ...data });
        },

        signupDev(data = {}) {
            return axios.post(`${this.URL}public/dev/register`, { ...data });
        },

        me() {
            return axios.get(`${this.URL}auth/me`);
        },

        qr(data = {}) {
            return axios.post(`${this.URL}public/qr_login`, data, {
                responseType: 'blob',
            });
        },

        qrLogin(id) {
            return axios.get(`${this.URL}public/auth_requests/${id}`);
        },

        meEdit(data = {}) {
            let id = data.id;
            delete data.id;

            return axios.put(`${this.URL}users/${id}`, { ...data });
        },

        pageView(data) {
            return axios.post(`${this.URL}public/merchants/page_view`, data);
        },

        geoIpRussia() {
            return axios.get(`${this.URL}public/geoip/is_russia.json`);
        },
    },
    API
);
