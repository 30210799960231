import api from '@/api/modules/users';
import { User } from '@/models/user';
import { Offer } from '@/models/offer';
import { CImage } from '@/models/image';
import project from './project';
import * as store from '../store';
import { STORAGE } from '../../api/storage';
import { USER_ROLES } from '@/models/user';
import { UserFabric } from '@/fabrics/UserFabric';

const state = _.defaults(
    {
        allItems: null,
        totalMerchants: null,
        total: null,
        items: [],
        opened: null,
        offer: null,
        error: '',
        pendingUploadImage: null,
        paymentEmail: null,
        finance: null,
        qrReferral: null,
    },
    store.state
);

const getters = _.defaults(
    {
        allItems: state => state.allItems,
        totalMerchants: state => state.totalMerchants,
        total: state => state.total,
        error: state => state.error,
        items: state => state.items,
        opened: state => state.opened,
        offer: state => state.offer,
        paymentEmail: state => state.paymentEmail,
        finance: state => state.finance,
        qrReferral: state => state.qrReferral,
    },
    store.getters
);

const actions = {
    async items({ state, commit }, data) {
        let method = 'items';

        if (data && data.role) {
            if (data.role == USER_ROLES.MERCHANT) method = 'merchants';
            if (data.role == USER_ROLES.CONSUMER) method = 'consumers';
            delete data.role;
        }

        try {
            let res = await api[method](data);
            commit('setItems', res.data['hydra:member']);
            commit('setItemsTotal', res.data['hydra:totalItems']);
            commit(store.PENDING, false);
            return state.items;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
        }

        return state.items;
    },

    async totalMerchants({ state, commit }, data) {
        try {
            let res = await api.totalMerchants(data);
            commit('setTotalMerchants', res.data);
            commit(store.PENDING, false);
            return getters.getItems;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
        }

        return state.items;
    },

    async putImage({ commit }, { data, uploadCallback }) {
        commit(store.START_REQUEST);
        commit('START_UPLOAD_IMAGE');

        try {
            let res = await api.putImage(data, uploadCallback);
            commit(store.PENDING, false);
            commit('END_UPLOAD_IMAGE');
            return new CImage(res.data);
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            commit('END_UPLOAD_IMAGE');
            if (err.code == 'ERR_NETWORK') return Promise.reject(err.code);
            return Promise.reject(store.parseError(err));
        }
    },

    async id({ state, commit }, data) {
        let i = _.find(state.items, s => s.id == data.id);
        commit('setOpened', i);
        return state.opened;
    },

    async paymentSignature({ commit, state, getters }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.signature(data);
            commit(store.PENDING, false);
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async testUnitpay({ commit, state, getters }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.testUnitpay(data);
            let res2 = await api.testUnitpay2(data);
            commit(store.PENDING, false);
            return res2.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async payment({ commit, state, getters }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.payment(data);
            commit(store.PENDING, false);
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async paymentDebug({ commit, state, getters }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.paymentDebug(data);
            commit(store.PENDING, false);
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async paymentInit({ commit, state, getters }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.paymentInit(data);
            commit(store.PENDING, false);
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async paymentsList({ commit, state, getters }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.paymentsList(data);
            commit(store.PENDING, false);
            return res.data['hydra:member'];
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async offer({ commit, state, getters }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.offer(data);
            commit('setOffer', res.data);
            commit(store.PENDING, false);
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async partnerView({ commit, state, getters }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.partnerView(data);
            commit('partnerView', res.data);
            commit(store.PENDING, false);
            return res.data;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },

    async finance({ commit, state, getters }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.finance(data);
            commit('setFinance', res.data);
            commit(store.PENDING, false);
            return state.finance;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
    async qrReferral({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.qrReferral(data);
            commit(store.PENDING, false);
            return new Promise((resolve, _) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(new Blob([res.data]));
            });
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
};

const mutations = _.defaults(
    {
        setItems(state, val) {
            state.items = val != null ? _.map(val, p => (p instanceof User ? p : UserFabric.initUser(p))) : [];
        },
        setItemsTotal(state, val) {
            state.total = val;
        },
        setTotalMerchants(state, val) {
            state.totalMerchants = val;
        },
        setOpened(state, i) {
            state.opened = i;
        },
        setOffer(state, i) {
            state.offer = [];

            _.each(i.items, item => {
                state.offer.push(new Offer(item));
            });
        },
        START_UPLOAD_IMAGE(state) {
            state.pendingUploadImage = true;
        },
        END_UPLOAD_IMAGE(state) {
            state.pendingUploadImage = false;
        },

        setPaymentEmail(state, val) {
            state.paymentEmail = val;
        },
        setFinance(state, items) {
            state.finance = items;
        },
        setQrReferral(state, items) {
            state.qrReferral = items;
        },
    },
    store.mutations
);

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
