import * as store from '../store';

const state = {
    mobileCalendar: null,
    mobileCalendarSimple: null,
    mobileFilters: null,
    mobileFiltersAmountSelected: 0,
    comments: null,
    projects: null,
    inactiveShop: null,
    usdtRequisites: null,
    withdrawalStripe: null,
    withdrawal: null,
    wizardSuccess: null,
    followersFilters: null,
    audienceFilters: null,
    financeFilters: {
        income: null,
        withdrawals: null,
    },
    plan: null,
};

const getters = _.defaults(
    {
        mobileCalendar: state => state.mobileCalendar,
        mobileCalendarSimple: state => state.mobileCalendarSimple,
        mobileFilters: state => state.mobileFilters,
        mobileFiltersAmountSelected: state => state.mobileFiltersAmountSelected,
        comments: state => state.comments,
        projects: state => state.projects,
        inactiveShop: state => state.inactiveShop,
        plan: state => state.plan,
        followersFilters: state => state.followersFilters,
        audienceFilters: state => state.audienceFilters,
        financeFilters: state => state.financeFilters,
        usdtRequisites: state => state.usdtRequisites,
        withdrawalStripe: state => state.withdrawalStripe,
        withdrawal: state => state.withdrawal,
        wizardSuccess: state => state.wizardSuccess,
    },
    store.getters
);

const actions = {};

const mutations = _.defaults(
    {
        setProjects(state, i) {
            state.projects = i;
        },
        setMobileFilters(state, i) {
            state.mobileFilters = i;
        },
        setMobileFiltersAmountSelected(state, i) {
            state.mobileFiltersAmountSelected = i;
        },
        setMobileCalendar(state, i) {
            state.mobileCalendar = i;
        },
        setMobileCalendarSimple(state, i) {
            state.mobileCalendarSimple = i;
        },
        setModalComments(state, i) {
            state.comments = i;
        },
        setInactiveShop(state, i) {
            state.inactiveShop = i;
        },
        setPlan(state, i) {
            state.plan = i;
        },
        followersFilters(state, i) {
            state.followersFilters = i;
        },
        audienceFilters(state, i) {
            state.audienceFilters = i;
        },
        financeFilters(state, i) {
            let type = i.type;
            delete i.type;
            state.financeFilters[type] = i;
        },
        setUSDTRequisites(state, i) {
            state.usdtRequisites = i;
        },
        setWithdrawalStripe(state, i) {
            state.withdrawalStripe = i;
        },
        setWithdrawal(state, i) {
            state.withdrawal = i;
        },
        wizardSuccess(state, i) {
            state.wizardSuccess = i;
        },
    },
    store.mutations
);

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
