import axios from 'axios';
import { API, APIURL } from '../index';

export default _.defaults(
    {
        URL: APIURL + 'comebackers',
        comebackersList(data) {
            return axios.get(`${this.URL}`, { params: data });
        },
        addComebacker(data) {
            return axios.post(`${this.URL}`, { ...data });
        },
        archiveComebacker(data) {
            let id = data.id;
            delete data.id;

            return axios.post(`${this.URL}/${id}/archive`, { params: data });
        },
        comebacker(data) {
            return axios.get(`${this.URL}/${data.id}`);
        },
        editComebacker(data) {
            let id = data.id;
            delete data.id;

            return axios.put(`${this.URL}/${id}`, { ...data });
        },
        removeComebacker(data) {
            return axios.delete(`${this.URL}/${data.id}`);
        },
        recoveryCommon(data) {
            let id = data.id;
            delete data.id;

            return axios.get(`${APIURL}projects/${id}/recovery?type=common`, { params: data });
        },
        recoveryPayment(data) {
            let id = data.id;
            delete data.id;

            return axios.get(`${APIURL}projects/${id}/recovery?type=payment`, { params: data });
        },
        recoveryComebacker(data) {
            let id = data.id;
            delete data.id;

            return axios.get(`${APIURL}projects/${id}/recovery?type=comebacker`, { params: data });
        },
        recovery(data) {
            let id = data.id;
            delete data.id;

            return axios.get(`${APIURL}projects/${id}/recovery`, { params: data });
        },
        recoveryEvents(data) {
            let id = data.id;
            delete data.id;

            return axios.get(`${APIURL}projects/${id}/recovery_events`, { params: data });
        },
        recoveryPaymentsEvents(data) {
            let id = data.id;
            delete data.id;

            return axios.get(`${APIURL}projects/${id}/recovery_payments_events`, { params: data });
        },
    },
    API
);
