<template lang="pug">
	.h-100
		router-view
</template>

<style src="@/assets/fonts/fontawesome/fontawesome.css"></style>
<style src="@/assets/fonts/golos/golos.css"></style>

<script>
import { getDirection } from './utils';
import { STORAGE } from '@/api/storage';
import { DEVICE } from '@/utils/device';

export default {
    components: {},
    data: () => ({}),
    computed: {},
    async beforeCreate() {
        if (STORAGE.hasToken()) {
            try {
                let u = await this.$store.dispatch('auth/me');
            } catch (err) {
                console.log(err);
            }
        }
    },
    beforeMount() {
        const direction = getDirection();
        if (direction.isRtl) {
            document.body.classList.add('rtl');
            document.dir = 'rtl';
            document.body.classList.remove('ltr');
        } else {
            document.body.classList.add('ltr');
            document.dir = 'ltr';
            document.body.classList.remove('rtl');
        }
    },
    methods: {},
    watch: {
        me(nv) {},
    },
};
</script>
