import { Model } from './model';
import { Plan } from './plan';
import { Money } from './money';
import moment from 'moment-timezone';

export class Promotion extends Model {
    constructor(data) {
        super(data);

        _.assignIn(this, data);
        this.plans = data.plans ? _.map(data.plans, p => new Plan(p)) : this.plans;
        this.canBeDeleted = this.isEditable;
        this.editable = data.editable;
    }

    setEditable(item) {
        this.canBeDeleted = item.isEditable;
    }

    setExpired(v) {
        this.expired = v;
    }

    static isValidNow(startAt, endAt) {
        return (
            (startAt == null && endAt == null) ||
            (moment().diff(moment(startAt)) > 0 && moment().diff(moment(endAt)) < 0)
        );
    }
    static includeDate(startAt, endAt, date = moment(new Date().getTime() + 3 * 60 * 60 * 1000)) {
        return startAt == null || endAt == null || date.isBetween(moment(startAt), moment(endAt));
    }
}
