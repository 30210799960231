import api from '@/api/modules/comebackers';
import * as store from '../store';
import { _ } from 'core-js';
import { Comebacker } from '@/models/comebacker';
import { RecoveryAnalytics } from '@/models/recoveryAnalytics';
import { RecoveryEvents } from '@/models/recoveryEvents';
import { RecoveryPaymentsEvents } from '@/models/recoveryPaymentsEvents';

const state = _.defaults(
    {
        items: [],
        edit: null,
        comebackerPlans: [],
        recoveryCommon: {},
        recoveryPayment: {},
        recoveryComebacker: {},
        recoveryEvents: [],
        totalRecoveryEvents: null,
        currentPageRecoveryEvents: null,
        totalPagesRecoveryEvents: null,
        recoveryPaymentsEvents: [],
        totalRecoveryPaymentsEvents: null,
        currentPageRecoveryPaymentsEvents: null,
        totalPagesRecoveryPaymentsEvents: null,
    },
    store.state
);

const getters = _.defaults(
    {
        items: state => state.items,
        edit: state => state.edit,
        comebackerPlans: state => state.comebackerPlans,
        recoveryCommon: state => state.recoveryCommon,
        recoveryPayment: state => state.recoveryPayment,
        recoveryComebacker: state => state.recoveryComebacker,
        recoveryEvents: state => state.recoveryEvents,
        totalRecoveryEvents: state => state.totalRecoveryEvents,
        currentPageRecoveryEvents: state => state.currentPageRecoveryEvents,
        totalPagesRecoveryEvents: state => state.totalPagesRecoveryEvents,
        recoveryPaymentsEvents: state => state.recoveryPaymentsEvents,
        totalRecoveryPaymentsEvents: state => state.totalRecoveryPaymentsEvents,
        currentPageRecoveryPaymentsEvents: state => state.currentPageRecoveryPaymentsEvents,
        totalPagesRecoveryPaymentsEvents: state => state.totalPagesRecoveryPaymentsEvents,
    },
    store.getters
);

const actions = {
    async comebackersList({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.comebackersList(data);
            commit('items', res.data['hydra:member']);
            commit(store.PENDING, false);
            return state.items;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
    async editComebacker({ commit, getters }, data) {
        commit(store.START_REQUEST, true);

        try {
            let res = await api.editComebacker(data);
            commit('edit', _.cloneDeep(res.data));
            commit(store.PENDING, false);
            return new Comebacker(res.data);
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
    async addComebacker({ commit, getters }, data) {
        commit(store.START_REQUEST, true);

        try {
            let res = await api.addComebacker(data);

            commit('add', res.data);
            commit(store.PENDING, false);
            return _.last(state.items);
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
    async archive({ commit, getters }, data = {}) {
        commit(store.START_REQUEST, true);

        try {
            let res = await api.archiveComebacker(data);
            commit('archive', _.cloneDeep(res.data));
            commit(store.PENDING, false);
            return new Comebacker(res.data);
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
    async remove({ commit, getters }, data) {
        commit(store.START_REQUEST, true);

        try {
            let res = await api.removeComebacker(data);
            commit('remove', data);
            commit(store.PENDING, false);
            return true;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
    async getRecoveryCommon({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.recoveryCommon(data);
            commit('setRecoveryCommon', res.data);
            commit(store.PENDING, false);
            //return state.items;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
    async getRecoveryPayment({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.recoveryPayment(data);
            commit('setRecoveryPayment', res.data);
            commit(store.PENDING, false);
            //return state.items;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
    async getRecoveryComebacker({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.recoveryComebacker(data);
            commit('setRecoveryComebacker', res.data);
            commit(store.PENDING, false);
            //return state.items;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
    async getRecoveryEvents({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.recoveryEvents(data);
            commit('setRecoveryEvents', res.data);
            commit(store.PENDING, false);
            //return state.items;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
    async getRecoveryPaymentsEvents({ state, commit }, data) {
        commit(store.START_REQUEST);

        try {
            let res = await api.recoveryPaymentsEvents(data);
            commit('setRecoveryPaymentsEvents', res.data);
            commit(store.PENDING, false);
            //return state.items;
        } catch (err) {
            commit(store.FAILURE, err);
            commit(store.PENDING, false);
            store.logError(err);
            commit('error', store.parseError(err));
            return Promise.reject(store.parseError(err));
        }
    },
};

const mutations = _.defaults(
    {
        items(state, i) {
            state.items = _.map(i, p => (p instanceof Comebacker ? p : new Comebacker(p)));

            /*Собираем все камбекеры с планами*/
            state.comebackerPlans = state.items.filter(p => p.plans.length > 0);
        },
        add(state, i) {
            state.items.push(new Comebacker(i));
        },
        archive(state, item) {
            let index = _.findIndex(state.items, i => i.id == item.id);

            if (index == -1 || state.items.length <= index) return;

            state.items[index].setArchive();
        },
        remove(state, item) {
            let index = _.findIndex(state.items, i => i.id == item.id);

            if (index == -1 || state.items.length <= index) return;

            state.items.splice(index, 1);
        },
        edit(state, item) {
            state.edit = item;

            let index = _.findIndex(state.items, v => v.id == item.id || v['@id'] == item['@id']);

            if (index != -1) {
                state.items[index] = item instanceof Comebacker ? item : new Comebacker(item);
            }

            state.items = _.cloneDeep(state.items);
        },
        setRecoveryCommon(state, i) {
            state.recoveryCommon = i instanceof RecoveryAnalytics ? i : new RecoveryAnalytics(i);
        },
        setRecoveryPayment(state, i) {
            state.recoveryPayment = i instanceof RecoveryAnalytics ? i : new RecoveryAnalytics(i);
        },
        setRecoveryComebacker(state, i) {
            state.recoveryComebacker = i instanceof RecoveryAnalytics ? i : new RecoveryAnalytics(i);
        },
        setRecoveryEvents(state, i) {
            state.recoveryEvents = _.map(i.data, p => (p instanceof RecoveryEvents ? p : new RecoveryEvents(p)));
            state.totalRecoveryEvents = i.totalItems;
            state.currentPageRecoveryEvents = i.currentPageRecoveryEvents;
            state.totalPagesRecoveryEvents = i.totalPagesRecoveryEvents;
        },
        setRecoveryPaymentsEvents(state, i) {
            state.recoveryPaymentsEvents = _.map(i.data, p =>
                p instanceof RecoveryPaymentsEvents ? p : new RecoveryPaymentsEvents(p)
            );
            state.totalRecoveryPaymentsEvents = i.totalItems;
            state.currentPageRecoveryPaymentsEvents = i.currentPageRecoveryPaymentsEvents;
            state.totalPagesRecoveryPaymentsEvents = i.totalPagesRecoveryPaymentsEvents;
        },
        metricExpand(state, item) {
            item.isExtended = !item.isExtended;
            if (item.isExtended) {
                let i = _.findIndex(state.recoveryComebacker.recoveryEfficiency, a => a.title === item.title);
                state.recoveryComebacker.recoveryEfficiency = _.cloneDeep([
                    ...state.recoveryComebacker.recoveryEfficiency.slice(0, i + 1),
                    ...item.plans,
                    ...state.recoveryComebacker.recoveryEfficiency.slice(i + 1),
                ]);
            } else {
                let plansTitle = item.plans.map(plan => plan.title);
                state.recoveryComebacker.recoveryEfficiency = _.filter(
                    state.recoveryComebacker.recoveryEfficiency,
                    s => !plansTitle.includes(s.title)
                );
            }
        },
    },
    store.mutations
);

export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations,
};
