<template lang="pug">
	b-toast(id="toast" toaster="b-toaster-top-center" :variant='type')
		span(v-html='message')
</template>

<script>
const types = ['success', 'error'];

export default {
    data() {
        return {
            message: null,
            type: types[0],
        };
    },
    methods: {
        show(type, message) {
            this.type = type == types[0] ? type : 'danger';
            this.message = message;
            this.$bvToast.show('toast');
        },
    },
};
</script>
