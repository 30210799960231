import { Model } from './model';
import { CImage } from './image';

export class Resource extends Model {
    constructor(data) {
        super(data);

        if (_.isNil(data)) {
            return;
        }

        _.assignIn(this, {
            title: data.title,
            description: data.description,
            image: data.image ? (data.image instanceof CImage ? data.image : new CImage(data.image)) : null,
            link: data.link,
            tgId: data.tgId,
            plan: data.plan ? new Model({ '@id': data.plan }) : {},
            user: data.user ? new Model({ '@id': data.user }) : {},
            project: data.project ? new Model({ '@id': data.project }) : {},
            status: data.status,
            avatarBg: CImage.BGColor(30, 60, data.title),
        });
    }

    isGroup() {
        return this.type == RESOURCE_TYPES.GROUP;
    }
    isChannel() {
        return this.type == RESOURCE_TYPES.CHANNEL;
    }
    isAlreadyUsed() {
        return this.status == RESOURCE_STATUSES.ALREADY_USED;
    }
    hasBotIssue() {
        return this.status == RESOURCE_STATUSES.NOT_ENOUGH_RIGHTS;
    }
    isBotRemoved() {
        return this.status == RESOURCE_STATUSES.BOT_REMOVED;
    }

    static isStatusAboutRights(v) {
        return v == RESOURCE_STATUSES.NOT_ENOUGH_RIGHTS;
    }
    static isStatusAlreadyUsed(v) {
        return v == RESOURCE_STATUSES.ALREADY_USED;
    }
}

export const RESOURCE_TYPES = {
    CHANNEL: 'Channel',
    GROUP: 'Group',
};

export const RESOURCE_STATUSES = {
    NOT_ENOUGH_RIGHTS: 'NOT_ENOUGH_RIGHTS',
    BOT_REMOVED: 'BOT_REMOVED',
    ALREADY_USED: 'ALREADY_ADDED',
};
