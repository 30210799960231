import _ from 'lodash';
import { Model } from './model';
import { User } from './user';
import { Plan } from './plan';
import { Money } from './money';
import { Comebacker } from './comebacker';

export class RecoveryEvents extends Model {
    constructor(data) {
        super(data);
        if (_.isNil(data)) {
            return;
        }

        _.assignIn(this, {
            comebacker: data.comebacker instanceof Comebacker ? data.comebacker : new Comebacker(data.comebacker),
            user: data.consumer instanceof User ? data.consumer : new User(data.consumer),
            date: data.date,
            id: data.id,
            mrr: data.mrr instanceof Money ? data.mrr : new Money(data.mrr),
            plan: data.plan instanceof Plan ? data.plan : new Plan(data.plan),
            status: data.status,
            errorGroup: data.errorGroup,
        });
    }
}
