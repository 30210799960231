import { Model } from './model';

export class Card extends Model {
    constructor(data) {
        super(data);

        if (_.isNil(data)) {
            return;
        }

        _.assignIn(this, {
            pan: data.pan,
            bank: data.bankName,
            system: data.system,
            status: data.status,
            errorMessage: data.errorMessage,
            accounts: data.accounts,
        });

        this._setPaymentSystemType();
    }

    _setPaymentSystemType() {
        let type;

        if (!this.pan) return;

        switch (String(this.pan).charAt(0)) {
            case '5':
                type = CARD_TYPES.MC;
                break;
            case '4':
                type = CARD_TYPES.VISA;
                break;
            case '2':
                type = CARD_TYPES.MIR;
                break;
            default:
                type = 'unknown';
        }

        this.systemType = type;
    }

    isTinkoff() {
        return this.system == 'TINKOFF';
    }
    isRemoved() {
        return this.status == 'REMOVED';
    }
}

export const CARD_TYPES = {
    VISA: 'visa',
    MC: 'mastercard',
    MIR: 'mir',
};
