import { Model } from './model';

export class ErrorPayment extends Model {
    constructor(data) {
        super(data);

        if (_.isNil(data)) {
            return;
        }

        _.assignIn(this, data);
    }

    static isCritical(v) {
        return v == ERROR_GROUPS[3].id;
    }
    static isHard(v) {
        return v == ERROR_GROUPS[2].id;
    }
    static isSoft(v) {
        return v == ERROR_GROUPS[0].id;
    }
    static isDeclineUnknown(v) {
        return v == ERROR_GROUPS[1].id;
    }
    static isFraud(v) {
        return v == ERROR_GROUPS[4].id;
    }
    static isNoFunds(v) {
        return v == ERROR_GROUPS[5].id;
    }
    static isUnknown(v) {
        return v == ERROR_GROUPS[6].id;
    }
    static isLimit(v) {
        return v == ERROR_GROUPS[7].id;
    }
}

export const ERROR_GROUPS = [
    { id: 'DECLINE_SOFT', title: 'DECLINE SOFT' },
    { id: 'DECLINE_UNKNOWN', title: 'DECLINE UNKNOWN' },
    { id: 'DECLINE_HARD', title: 'DECLINE_HARD' },
    { id: 'CRITICAL', title: 'CRITICAL' },
    { id: 'FRAUD', title: 'FRAUD' },
    { id: 'NO_FUNDS', title: 'NO_FUNDS' },
    { id: 'UNKNOWN', title: 'UNKNOWN' },
    { id: 'DECLINE_LIMIT', title: 'DECLINE_LIMIT' },
];
